import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import { createSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import RightsFilterButton from '../button/RightsFilterButton';

interface IUserFilterSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    required ?: boolean;
    label : string;
    name : string | null;
    employeeNr : string | null;
    isActive : boolean | null;
    onChange? : (
        name : string | null,
        employeeNo : string | null,
        isActive : boolean | null,
    ) => void;    
}

interface IUserFilterSelectorState {
    open : boolean;
}

export default class UserFilterSelector extends React.PureComponent<IUserFilterSelectorProps, IUserFilterSelectorState> {
    constructor(props : IUserFilterSelectorProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    private toggleOpen = (open : boolean) => {
        this.setState({ open, });
    }

    private onFilterIconButtonClick = () => {
        this.toggleOpen(!this.state.open);
    }

    private onFilterFocusClick = (event : React.FocusEvent<HTMLInputElement>) => {
        this.toggleOpen(true);
    }

    private onClearClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.onChange(null, null, null);
    }

    private onChange = (
        name : string | null,
        employeeNo : string | null,
        isActive : boolean | null,
    ) => {
        this.setState({ open: false, });

        if (!this.props.onChange) return;
        this.props.onChange(name, employeeNo, isActive);
    }

    private getName = (props: IUserFilterSelectorProps) => props.name;
    private getEmployee = (props : IUserFilterSelectorProps) => props.employeeNr;
    private getIsActive = (props : IUserFilterSelectorProps) => props.isActive;

    private getLabel = createSelector(
        [
            this.getName,
            this.getEmployee,
            this.getIsActive,
        ],
        (name, employeeNr, isActive) => {
            const result = [];

            if (name) result.push(name);
            if (employeeNr) result.push(employeeNr);
            if (isActive) result.push('Active');
            if (!isActive) result.push('Inactive');

            return result.join(' - ');
        }
    )

    public render = () => {
        const {
            required,
            fullWidth,
            label,
            id,
            name,
            employeeNr,
            isActive,
        } = this.props;
        const { open } = this.state;

        const value = this.getLabel(this.props);
        
        return (
            <FormControl fullWidth={fullWidth} error={required && !employeeNr} required={required}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Input
                    onFocus={this.onFilterFocusClick}
                    onChange={undefined}
                    value={value}
                    readOnly
                    endAdornment={
                        <InputAdornment position={'end'}>
                            {
                                (name || employeeNr) &&
                                <IconButton
                                    aria-label={'clear filter'}
                                    onClick={this.onClearClick}
                                >
                                    <CloseIcon className={'cr'}/>
                                </IconButton>
                            }
                            <RightsFilterButton
                                id={id}
                                open={open}
                                onClick={this.onFilterIconButtonClick}
                                onChange={this.onChange}
                                name={name}
                                employeeNr={employeeNr}
                                isActive={isActive}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    }
}