import { FormControl, FormControlLabel, FormHelperText, Switch, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import RoleDropdown from '../../../custom/dropdowns/RoleDropdown';
import { IRole } from '../../../../../@types/model/right';

interface IUserDetailTabProps {
    onEmployeeNumberChange : (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    onIsActiveChange : (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => void;
    onRoleChange : (role : IRole) => void;


    roleId : number | null;

    employeeNr : string;
    isActive : boolean;
}

interface IUserDetailTabState {}

export default class UserDetailTab extends React.PureComponent<IUserDetailTabProps, IUserDetailTabState> {
    constructor(props : IUserDetailTabProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { 
            employeeNr,
            roleId,
            isActive,
            onIsActiveChange,
            onEmployeeNumberChange,
            onRoleChange,
        } = this.props;
        return (
            <div className={'fdr ais'}>
                <div className={'fdc aifs p5 mb10 pr20'}>
                    <FormControl>
                        <TextField
                            error={!employeeNr}
                            autoComplete='off'
                            id='employeeNrChanged'
                            label='Employee Number'
                            value={employeeNr}
                            onChange={onEmployeeNumberChange}
                            margin='normal'
                        />
                        {
                            !employeeNr &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className={'fdc aifs p5 mb10 pr20'}>
                    <RoleDropdown roleId={roleId} onSelected={onRoleChange} required/>
                </div>
                <div className={'fdr aic p5 mb10 pr20'}>
                    <FormControlLabel
                        labelPlacement='start'
                        className='cp'
                        control={
                            <Switch
                                checked={isActive}
                                color='primary'
                                onChange={onIsActiveChange}/>
                        }
                        label={`Is Active?`}/>
                </div>
                <div className={'fdc ais p5 mb10 pr20'}>
                </div>
            </div>
        );
    }
}
