import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import FileThumbnail from '../../custom/fileItem/FileThumbnail';
import { INCIDENT_TYPE_TO_TEXT } from '../../../../appConstants';
import IncidentFunctions from '../../../../store/incident/functions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IIncidentFilesViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentFilesViewState {
    isResigning : boolean;

    selectedFileIndex? : number;
}

export default class IncidentFilesView extends React.PureComponent<IIncidentFilesViewProps, IIncidentFilesViewState> {
    constructor(props : IIncidentFilesViewProps) {
        super(props);
        this.state = {
            isResigning: false,
        };
    }

    public onResignFilesClick = async () => {
        if (this.props.incident) {
            this.setState({
                isResigning: true,
            });

            await IncidentFunctions.resignFiles(this.props.incident.guid);

            this.setState({
                isResigning: false,
            });
        }
    }

    public onOpenFileCarousel = (index : number) => {
        if (this.props.incident) {
            this.setState({
                selectedFileIndex: index,
            });
        }
    }

    public onFileCarouselClose = () => {
        this.setState({
            selectedFileIndex: undefined,
        });
    }

    public render = () => {
        const { isResigning } = this.state;
        const { incident } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                FILES
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Resign Files'>
                                <div>
                                    <IconButton color='secondary' disabled={isResigning} onClick={this.onResignFilesClick} aria-label='Resign Files'>
                                        <CloudCircleIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc oya'>
                    {
                        !isResigning && incident.files.length > 0 &&
                        <div className='fdr fww aic'>
                        {
                            incident.files.map((n, i) => {
                                return (
                                    <FileThumbnail className='mr15' title={INCIDENT_TYPE_TO_TEXT[n.incidentFileType]} key={`file_${n.id}`} file={n.file} index={i} onOpenFileCarousel={this.onOpenFileCarousel} />);
                            })
                        }
                        </div>
                    }
                    {
                        !isResigning && incident.files.length === 0 &&
                        <div className='fdc aic'>
                            <Typography variant='body1' component='p'>
                                No Files
                            </Typography>
                        </div>
                    }
                    {
                        isResigning &&
                        <div className='fdc aic'>
                            <CircularProgress />
                        </div>
                    }
                        <FileCarouselDialog fullWidth maxWidth='md' selectedIndex={this.state.selectedFileIndex} files={incident.files} open={this.state.selectedFileIndex !== undefined} onClose={this.onFileCarouselClose} />
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
