import { IUserListView } from '../../../../@types/model/right';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import { Transitions } from '../../custom/animations/Transitions';
import MaterialTable from '../../custom/materialTable/Table';
import TableFunctions from '../../custom/materialTable/functions';
import { createSelector } from 'reselect';
import UserFilterSelector from '../../custom/selector/UserFilterSelector';
import RightsFunctions from '../../../../store/right/functions';
import UserEditDialog from './dialog/Edit';

interface IUserListProps {
    userList : Array<IUserListView>;
    isLoading : boolean;
}

interface IUserListState {

    editItem? : IUserListView;

    name : string;
    employeeNr : string;
    isActive : boolean;

    isEditing : boolean;
}

class UserList extends React.Component<IUserListProps, IUserListState> {
    constructor(props : IUserListProps) {
        super(props);

        this.state = {
            editItem: undefined,
            isEditing: false,
            name: '',
            employeeNr: '',
            isActive: true,
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    private loadData() {
        RightsFunctions.loadUsers(true);
    }

    public onFilterChange = (
        name : string | null,
        employeeNr : string | null,
        isActive : boolean | null,
    ) => {
        this.setState({
            name : name ?? '',
            employeeNr : employeeNr ?? '',
            isActive : isActive ?? true,
        });
    }

    private getUsers = (state : IUserListState, props : IUserListProps) => props.userList;
    private getName = (state : IUserListState) => state.name.toLowerCase();
    private getEmployeeNumber = (state : IUserListState) => state.employeeNr.toLowerCase();
    private getIsActive = (state : IUserListState) => state.isActive;

    private getFilteredData = createSelector([
        this.getUsers,
        this.getName,
        this.getEmployeeNumber,
        this.getIsActive,
    ], (
        users,
        name,
        employeeNr,
        isActive,
    ) => {
        return users
            .filter(e => e.isActive == isActive)
            .filter(e => !name || e.name.toLowerCase().includes(name))
            .filter(e => !employeeNr || !e.employeeNr || e.employeeNr.toLowerCase().includes(employeeNr));
    },
    );

    private setEditItem = (openDialog : boolean, item? : IUserListView) => {
        this.setState({
            editItem: item,
            isEditing: openDialog,
        });
    }

    private onEditItemClose = () => {
        this.setState({
            editItem: undefined,
            isEditing: false,
        });
    }

    private refresh = () => {
        this.loadData();
    }

    public render() {
        const {
            isEditing,
            editItem,
            name,
            employeeNr,
            isActive,
        } = this.state;
        const {
            isLoading,
        } = this.props;

        const filteredUsers = this.getFilteredData(this.state, this.props);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar className={'jcsb'}>
                        <div className={'aic flx2'}>
                            <Typography variant='h5' color='inherit'>
                                Users
                            </Typography>
                            <IconButton color='primary' onClick={this.refresh}>
                                <Tooltip title='Refresh'>
                                    <Icon>
                                        refresh
                                    </Icon>
                                </Tooltip>
                            </IconButton>
                        </div>
                        <div className='flx1 aic p5 mb10'>
                           <UserFilterSelector
                                label={'Filter By...'}
                                name={name}
                                employeeNr={employeeNr}
                                isActive={isActive}
                                fullWidth
                                onChange={this.onFilterChange}
                           />
                        </div>
                    </Toolbar>
                    <MaterialTable<IUserListView>
                        id='userListTable'
                        data={filteredUsers}
                        isLoading={isLoading}
                        rowsPerPage={100}
                        selectable={false}
                        columns={[{
                            header: '',
                            width: 100,
                            paddingRight: '0',
                            renderCell: row => (
                                <div>
                                    <IconButton onClick={() => this.setEditItem(true, row)}>
                                        <Icon>
                                            edit
                                        </Icon>
                                    </IconButton>
                                </div>
                            ),
                        },        {
                            header: 'NAME',
                            field: 'name',
                            width: 250,
                            enableSort: true,
                            enableFilter: true,
                        },        {
                            header: 'EMAIL',
                            field: 'email',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        },        {
                            header: 'EMPLOYEE NR',
                            field: 'employeeNr',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        },        {
                            header: 'ROLE',
                            field: 'role',
                            width: 150,
                            enableSort: true,
                            enableFilter: true,
                        },        {
                            header: 'ACTIVE',
                            field: 'isActive',
                            width: 120,
                            enableSort: true,
                            renderCell: row => !!!row.isActive ? 'NO' : 'YES',
                            enableFilter: true,
                        },        {
                            header: 'CREATED ON',
                            field: 'createdOn',
                            enableSort: true,
                            width: 175,
                            renderCell: row => TableFunctions.formatDateTime(row.createdOn.toString()),
                            enableFilter: true,
                        },        {
                            header: 'UPDATED ON',
                            field: 'updatedOn',
                            enableSort: true,
                            renderCell: row => TableFunctions.formatDateTime(row.updatedOn.toString()),
                            enableFilter: true,
                        }]}
                    />
                    <UserEditDialog transition={Transitions.Up} open={isEditing || !!editItem} user={editItem!} onClose={this.onEditItemClose} />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        userList: state.rights.users,
        isLoading: state.rights.isLoadingUsers,
    };
};

export default connect(
    mapStateToProps,
)(UserList);
