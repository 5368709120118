import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React from 'react';
import Fab from '@material-ui/core/Fab';
import { FormControl, TextField } from '@material-ui/core';
import OutsideClicker from '../custom/OutsideClicker';
import OutlinedToggleButton from '../custom/button/OutlinedToggleButton';

interface IRightsFilterPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    name : string | null;
    employeeNr : string | null;
    isActive : boolean | null;
    onChange ?: (name : string | null, employeeNr : string | null, isActive : boolean | null) => void;
    onCancelClick ?: () => void;
}

interface IRightsFilterPopperState {
    name : string;
    employeeNr : string;
    isActive : boolean;
}

export default class RightsFilterPopper extends React.PureComponent<IRightsFilterPopperProps, IRightsFilterPopperState> {
    constructor(props : IRightsFilterPopperProps) {
        super(props);
        this.state = {
            name : '',
            employeeNr : '',
            isActive : true,
        };
    }

    public componentDidUpdate = (prevProps : IRightsFilterPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {
                name,
                employeeNr,
                isActive,
            } = this.props;

            this.setState({
                name : name ?? '',
                employeeNr : employeeNr ?? '',
                isActive: isActive ?? true,
            });
        }
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ name : event.target.value });
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({ employeeNr : event.target.value });
    }

    private onApplyClick = (event ?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;

        const {
            name,
            employeeNr,
            isActive,
        } = this.state;

        this.props.onChange(name, employeeNr, isActive);
    }

    private isActiveChange = (value : boolean) => {
        this.setState({
            isActive: !value,
        });
    }

    public render = () => {
        const {
            open,
            anchorEl,
            id,
            onCancelClick,
        } = this.props;

        const {
            name,
            employeeNr,
            isActive,
        } = this.state;

        return (
            <Popper
                id={`${id}_rights_filter_menu`}
                open={open}
                anchorEl={anchorEl}
                transition
                className={'filter-popper'}
                placement={'bottom'}
            >
                {
                    ({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className='filter-popper-offset'>
                                {
                                    open &&
                                    <OutsideClicker onClickedOutside={onCancelClick}>
                                        <form onSubmit={this.onApplyClick} className={'fdc'}>
                                            <div className={'fdr'}>
                                                <div className={'flx1 pr5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField
                                                            autoComplete={'off'}
                                                            id={'Name'}
                                                            label={'Name'}
                                                            value={name}
                                                            onChange={this.onNameChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className={'flx1 pr5'}>
                                                    <FormControl fullWidth> 
                                                        <TextField 
                                                            autoComplete='off'
                                                            id={'employeeNr'}
                                                            label={'Employee Nr'}
                                                            value={employeeNr}
                                                            onChange={this.onEmployeeNrChange}
                                                            margin={'normal'}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className='fdr'>
                                                <div className={'aifs pr5 jcc pt10'}>
                                                    <FormControl fullWidth>
                                                        <OutlinedToggleButton
                                                            labelText='Is Active'
                                                            value={isActive}
                                                            onClick={this.isActiveChange}
                                                            selected={isActive}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className={'fdr aic pb15'}>
                                                <span className='flx1'/>
                                                <Fab variant={'extended'} color={'secondary'} className={'fab cancel'} size={'small'} onClick={onCancelClick} type={'button'}>
                                                    CANCEL
                                                </Fab> 
                                                <Fab variant={'extended'} color={'secondary'} className={'fab save'} size={'small'} type={'submit'}>
                                                    APPLY
                                                </Fab>
                                            </div>
                                        </form>
                                    </OutsideClicker>
                                }
                            </div>
                        </Fade>
                    )
                }
            </Popper>
        )
    }
}