import ObservationHttpService from '../../service/http/observationHttpService';
import generalFunctions from '../general/functions';
import { dispatch, getState } from '..';
import observationActions from './actions';
import { IDivision } from '../../@types/model/division';
import { IFileUpload } from '../../@types/viewmodel/file';
import FileFunctions from '../file/functions';
import { EnumFileCategory, ILatLng } from '../../@types/model/base';
import lodash from 'lodash';
import { IObservation } from '../../@types/model/observation';

export default class ObservationFunctions {
    public static async get(id? : number, guid? : string) {
        try {
            dispatch(observationActions.setLoadingObservations(true));
            const result = await ObservationHttpService.get(id, guid);

            dispatch(observationActions.setObservation(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading observation', e);
        } finally {
            dispatch(observationActions.setLoadingObservations(false));
        }
    }

    public static async getAll(startDateUnixMilli : number, endDateUnixMilli : number) {
        try {
            dispatch(observationActions.setLoadingObservations(true));
            const result = await ObservationHttpService.getAll(startDateUnixMilli, endDateUnixMilli);

            dispatch(observationActions.setObservations(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading observations', e);
        } finally {
            dispatch(observationActions.setLoadingObservations(false));
        }
    }

    public static async resignFiles(guid? : string) {
        try {
            dispatch(observationActions.setLoadingObservations(true));
            const result = await ObservationHttpService.resignFiles(guid);

            const state = getState();

            if (state.observation.selectedObservation) {
                const selectedObservation = Object.assign({}, state.observation.selectedObservation);

                selectedObservation.files = result.data;

                dispatch(observationActions.setObservation(selectedObservation));
            }

            const observations = state.observation.observations.slice();
            const index = observations.findIndex(n => n.guid === guid);
            if (index > -1) {
                observations[index].files = result.data;
                dispatch(observationActions.setObservations(observations));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading observation files', e);
        } finally {
            dispatch(observationActions.setLoadingObservations(false));
        }
    }

    public static async create(guid : string, date : number, division : IDivision, description : string, geo : ILatLng, files : Array<IFileUpload>) {
        try {
            dispatch(observationActions.setLoadingObservations(true));

            const clearedFiles = lodash.cloneDeep(files);
            clearedFiles.forEach(n => n.file = null);

            const result = await ObservationHttpService.create(guid, date, division, description, geo, clearedFiles);

            if (result.data) {
                const observations = getState().observation.observations.slice();
                observations.push(result.data);
                dispatch(observationActions.setObservations(observations));
                dispatch(observationActions.setObservation(result.data));
            }

            files.forEach(n => FileFunctions.queueObservationFile({ ...n }, EnumFileCategory.Observation, guid));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while creating observation.', e);
        } finally {
            dispatch(observationActions.setLoadingObservations(false));
        }

        return null;
    }

    public static async update(guid : string, date : number, division : IDivision, description : string, geo : ILatLng, files : Array<IFileUpload>) {
        try {
            dispatch(observationActions.setLoadingObservations(true));

            const clearedFiles = lodash.cloneDeep(files);
            clearedFiles.forEach(n => n.file = null);

            const result = await ObservationHttpService.update(guid, date, division, description, geo, clearedFiles);

            if (result.data) {
                const observations = getState().observation.observations.slice();
                const index = observations.findIndex(x => x.guid === result.data.guid);

                observations.splice(index, 1, result.data);
                dispatch(observationActions.setObservations(observations));
            }

            files.forEach(n => FileFunctions.queueObservationFile({ ...n }, EnumFileCategory.Observation, guid));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while updating observation.', e);
        } finally {
            dispatch(observationActions.setLoadingObservations(false));
        }

        return null;
    }

    public static async remove(observation : IObservation) {
        try {
            dispatch(observationActions.setLoadingObservations(true));

            await ObservationHttpService.remove(observation);

            const observations = getState().observation.observations.slice();

            const index = observations.findIndex(x => x.guid === observation.guid);
            observations.splice(index, 1);

            dispatch(observationActions.setObservations(observations));
            dispatch(observationActions.setLoadingObservations(false));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while removing observation', e);
            dispatch(observationActions.setLoadingObservations(false));
            throw e;
        }
    }
}
