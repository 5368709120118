import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import { IObservation } from '../../../../@types/model/observation';
import moment from 'moment';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AvatarIcon from '../../custom/AvatarIcon';
import observationFunctions from '../../../../store/observation/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { dispatch } from '../../../../store';
import observationActions from '../../../../store/observation/actions';
import FileThumbnail from '../../custom/fileItem/FileThumbnail';
import Tooltip from '@material-ui/core/Tooltip';
import ReactToPrint from 'react-to-print';
import Icon from '@material-ui/core/Icon';
import generalFunctions from '../../../../store/general/functions';
import { setPageTitle, resetPageTitle } from '../../../../service/helper/functionHelperService';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import LocationInput from '../../custom/input/LocationInput';
import { RIGHTS } from '../../../../appConstants';
import ObservationEditDialog from './ObservationEditDialog';

interface IObservationDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    observation? : IObservation;
    guid? : string;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    enableEdit : boolean;
}

interface IObservationDialogState {
    isLoading : boolean;

    selectedFileIndex? : number;
}

class ObservationDialog extends React.Component<IObservationDialogProps, IObservationDialogState> {
    private componentRef : any;
    constructor(props : IObservationDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : IObservationDialogProps, prevState : IObservationDialogState) => {
        if (prevProps.guid !== this.props.guid) {
            if (this.props.guid) {
                this.loadObservation();
            }
        }
    }

    private loadObservation = async () => {
        this.setState({
            isLoading: true,
        });

        await observationFunctions.get(undefined, this.props.guid ?? this.props.observation?.guid);

        if (this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    public onClose = () => {
        this.setState({
            isLoading: false,
        });
        dispatch(observationActions.setObservation());

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public onResignFilesClick = async () => {
        if (this.props.observation) {
            this.setState({
                isLoading: true,
            });

            await observationFunctions.resignFiles(this.props.observation.guid);

            this.setState({
                isLoading: false,
            });
        }
    }

    public onOpenFileCarousel = (index : number) => {
        if (this.props.observation) {
            this.setState({
                selectedFileIndex: index,
            });
        }
    }

    public onFileCarouselClose = () => {
        this.setState({
            selectedFileIndex: undefined,
        });
    }

    public render = () => {
        const { isLoading } = this.state;
        const { observation, fullScreen, transition, guid, enableEdit } = this.props;

        return (
            <Dialog
                    open={!!observation || !!guid}
                    onClose={this.onClose}
                    aria-labelledby='observation-info-dialog-title'
                    aria-describedby='observation-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                    fullScreen={fullScreen}
                    TransitionComponent={transition}
                    transitionDuration={500}
                >
                    <div
                    ref={el => (this.componentRef = el)}>
                        <AppBar className='posr'>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                {
                                    observation &&
                                    <Typography variant='h6' color='inherit'>
                                        {observation.number} - {moment.utc(observation.date).format('YYYY/MM/DD')}
                                    </Typography>
                                }
                                <Tooltip title='Refresh'>
                                    <div>
                                        <IconButton onClick={this.loadObservation} disabled={isLoading} color='inherit'>
                                            <Icon>
                                                refresh
                                            </Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <span className='flx1' />
                                {
                                    enableEdit &&
                                    observation &&
                                    <ObservationEditDialog
                                        observation={observation}
                                        maxWidth='md'
                                        fullWidth
                                        fullScreen
                                    />
                                }
                                {
                                    observation &&
                                    <ReactToPrint
                                        trigger={() =>
                                            <div>
                                                <Tooltip title='Print'>
                                                    <IconButton color='inherit'>
                                                        <Icon>
                                                            print
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                        onPrintError={generalFunctions.showPrintError}
                                        content={() => this.componentRef}
                                        onBeforePrint={() => setPageTitle(`${observation.number}`)}
                                        onAfterPrint={resetPageTitle}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='p15'>
                                {
                                    !isLoading && observation &&
                                    <div className='fdc'>
                                        <div className='fdr aic mb15 cpd'>
                                            <Typography variant='h5' className='fw500'>USER INFO</Typography>
                                        </div>
                                        <div className='fdr'>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Area:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { observation.divisionCode && observation.divisionName ? `${observation.divisionCode} - ${observation.divisionName}` : '' }
                                            </Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Created By:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { observation.createdByName.toTitleCase() }
                                            </Typography>
                                        </div>
                                        <div className='fdr'>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Updated By:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { observation.updatedByName.toTitleCase() }
                                            </Typography>
                                        </div>
                                        <div className='fdr aic mb15 mt15 cpd'>
                                            <Typography variant='h5' className='fw500'>DESCRIPTION</Typography>
                                        </div>
                                        <Typography variant='body1' component='p'>
                                            {
                                                observation.description
                                            }
                                        </Typography>
                                        <div className='fdr aic mb15 mt15 cpd'>
                                            <AvatarIcon iconClass='icon-files' iconSize={28} iconClassName='cpd' className='mr10 bcw'/>
                                            <Typography variant='h5' className='fw500'>FILES</Typography>
                                            <span className='flx1' />
                                            <Tooltip title='Resign Files'>
                                                <IconButton onClick={this.onResignFilesClick} aria-label='Resign Files'>
                                                    <CloudCircleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {
                                            observation.files.length > 0 &&
                                            <div className='fdr aic'>
                                            {
                                                observation.files.map((n, i) => (
                                                    <FileThumbnail key={`file_${n.id}`} index={i} file={n.file} onOpenFileCarousel={this.onOpenFileCarousel} />
                                                ))
                                            }
                                            </div>
                                        }
                                        {
                                            observation.files.length === 0 &&
                                            <div className='fdc aic'>
                                                <Typography variant='body1' component='p'>
                                                    No Files
                                                </Typography>
                                            </div>
                                        }
                                        <div className={'fdr mb10 pl10 pr10 h300'}>
                                            <LocationInput id='view-dialog-map' geo={observation.geo} />
                                        </div>
                                        <FileCarouselDialog fullWidth maxWidth='md' selectedIndex={this.state.selectedFileIndex} files={observation.files} open={this.state.selectedFileIndex !== undefined} onClose={this.onFileCarouselClose} />
                                    </div>
                                }
                                {
                                    isLoading &&
                                    <div className='flx1 fdc aic'>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        observation: state.observation.selectedObservation,
        enableEdit: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.OBS_EDIT),
    };
};

export default connect(
    mapStateToProps,
)(ObservationDialog);
