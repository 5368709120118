import IncidentHttpService from '../../service/http/incident/incidentHttpService';
import VirusIncidentHttpService from '../../service/http/incident/virusIncidentHttpService';
import generalFunctions from '../general/functions';
import { dispatch, getState } from '..';
import incidentActions from './actions';
import { IIncident } from '../../@types/model/incident';
import { IIncidentGeneralInfoCapture, IIncidentSAPSCapture, IIncidentComplainantCapture, IIncidentCapture, IIncidentEvidence, IIncidentSuspects, IIncidentUpdateSave, IIncidentCourtCaseCreate } from '../../@types/viewmodel/incident';
import FileFunctions from '../file/functions';
import { EnumFileCategory } from '../../@types/model/base';
import lodash from 'lodash';
import { IFileUpload } from '../../@types/viewmodel/file';

export default class IncidentFunctions {
    public static async save(incident : IIncident, incidentFiles? : Array<IFileUpload>, fileType? : EnumFileCategory) {
        try {
            dispatch(incidentActions.setIncidentSaving(true));

            const result = await IncidentHttpService.save(incident);

            dispatch(incidentActions.setSelectedIncident(result.data));

            const incidents = getState().incident.incidents.slice();
            const index = incidents.findIndex(x => x.guid === incident.guid);
            incidents.splice(index, 1, result.data);

            dispatch(incidentActions.setIncidents(incidents));
            dispatch(incidentActions.setIncidentSaving(false));
            if (incidentFiles) {
                incidentFiles.forEach(n => FileFunctions.queueIncidentFile({ ...n }, fileType ?? EnumFileCategory.Incident, incident.guid));
            }

            return result;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving incident', e);
            dispatch(incidentActions.setIncidentSaving(false));
        }
    }

    public static async saveUpdate(incidentUpdate : IIncidentUpdateSave) {
        try {
            dispatch(incidentActions.setIncidentSaving(true));

            const result = await IncidentHttpService.saveUpdate(incidentUpdate);

            if (result.data) {
                const incidents = getState().incident.incidents.slice();
                const index = incidents.findIndex(x => x.guid === incidentUpdate.incidentGuid);
                incidents.splice(index, 1, result.data);

                dispatch(incidentActions.setIncidents(incidents));
                incidentUpdate.files.forEach(n =>
                    FileFunctions.queueIncidentFile({ ...n }, EnumFileCategory.IncidentUpdate, `${incidentUpdate.incidentGuid},${incidentUpdate.guid}`),
                );
                dispatch(incidentActions.setIncidentSaving(false));
                dispatch(incidentActions.setSelectedIncident(result.data));
            }

            return result;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving incident update', e);
            dispatch(incidentActions.setIncidentSaving(false));
        }
    }

    public static async remove(incident : IIncident) {
        try {
            dispatch(incidentActions.setIncidentLoading(true));

            await IncidentHttpService.remove(incident);

            const incidents = getState().incident.incidents.slice();

            const index = incidents.findIndex(x => x.guid === incident.guid);
            incidents.splice(index, 1);

            dispatch(incidentActions.setIncidents(incidents));
            dispatch(incidentActions.setIncidentLoading(false));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while removing incident', e);
            dispatch(incidentActions.setIncidentLoading(false));
            throw e;
        }
    }

    public static async setSelected(incident? : IIncident) {
        try {
            dispatch(incidentActions.setSelectedIncident(incident));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident', e);
        }
    }

    public static async getSelected(id? : number, guid? : string) {
        try {
            dispatch(incidentActions.setIncidentLoading(true));

            const result = await IncidentHttpService.get(id, guid);

            dispatch(incidentActions.setSelectedIncident(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident', e);
        }

        dispatch(incidentActions.setIncidentLoading(false));
    }

    public static async setEdit(incident? : IIncident) {
        try {
            dispatch(incidentActions.setEditIncident(incident));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident', e);
        }
    }

    public static async getAll(startDateUnixMilli : number, endDateUnixMilli : number, division? : string) {
        try {
            dispatch(incidentActions.setIncidentLoading(true));

            const result = await IncidentHttpService.getAll(startDateUnixMilli, endDateUnixMilli, division);

            dispatch(incidentActions.setIncidents(result.data));
            dispatch(incidentActions.setIncidentLoading(false));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while loading incidents', e);
                dispatch(incidentActions.setIncidentLoading(false));
            }
        }

    }

    public static async resignFiles(guid? : string) {
        try {
            const result = await IncidentHttpService.resignFiles(guid);

            const state = getState();

            if (state.incident.selectedIncident) {
                const selectedIncident = Object.assign({}, state.incident.selectedIncident);

                selectedIncident.files = result.data;

                dispatch(incidentActions.setSelectedIncident(selectedIncident));
            }

            const incidents = state.incident.incidents.slice();
            const index = incidents.findIndex(n => n.guid === guid);
            if (index > -1) {
                incidents[index].files = result.data;
                dispatch(incidentActions.setIncidents(incidents));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident files', e);
        }
    }

    public static async create(
        guid : string,
        generalInfo : IIncidentGeneralInfoCapture,
        saps : IIncidentSAPSCapture,
        complainant : IIncidentComplainantCapture,
        incident : IIncidentCapture,
        evidence : IIncidentEvidence,
        suspects : IIncidentSuspects,
        courtCase ?: IIncidentCourtCaseCreate | null,
    ) {
        try {
            dispatch(incidentActions.setIncidentLoading(true));

            // Copy passed in parameters as incidentHttpService.create mutates incident, evidence and suspect.
            const requestData = {
                guid: guid.slice(),
                generalInfo: {
                    ...generalInfo,
                },
                saps: {
                    ...saps,
                },
                complainant: {
                    ...complainant,
                },
                incident: lodash.cloneDeep(incident),
                evidence: lodash.cloneDeep(evidence),
                suspects: lodash.cloneDeep(suspects),
                courtCase: !courtCase ? null : lodash.cloneDeep(courtCase),
            };

            const result = await IncidentHttpService.create(
                guid,
                requestData.generalInfo,
                requestData.saps,
                requestData.complainant,
                requestData.incident,
                requestData.evidence,
                requestData.suspects,
                requestData.courtCase,
            );

            if (result.data) {
                const incidents = getState().incident.incidents.slice();
                incidents.push(result.data);
                dispatch(incidentActions.setIncidents(incidents));
                dispatch(incidentActions.setSelectedIncident(result.data));
            }

            incident.incidentFiles.forEach(n => FileFunctions.queueIncidentFile({ ...n }, EnumFileCategory.Incident, requestData.guid));
            evidence.evidenceFiles.forEach(n => FileFunctions.queueIncidentFile({ ...n }, EnumFileCategory.Evidence, requestData.guid));
            suspects.suspectFiles.forEach(n => FileFunctions.queueIncidentFile({ ...n }, EnumFileCategory.Suspect, requestData.guid));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while creating incident.', e);
        } finally {
            dispatch(incidentActions.setIncidentLoading(false));
        }

        return null;
    }

    public static async getAllVirusIncidents(startDateUnixMilli : number, endDateUnixMilli : number) {
        try {
            dispatch(incidentActions.setLoadingCovid19Incidents(true));

            const result = await VirusIncidentHttpService.getAll(startDateUnixMilli, endDateUnixMilli);

            dispatch(incidentActions.setCovid19Incidents(result.data));
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading covid-19 incidents', e);
        } finally {
            dispatch(incidentActions.setLoadingCovid19Incidents(false));
        }

    }

    public static async setApproval(guids : Array<string>, approve : boolean) {
        try {
            dispatch(incidentActions.setIncidentLoading(true));

            const result = await IncidentHttpService.setApproval(guids, approve);

            const incidents = getState().incident.incidents.slice();

            result.data.forEach((incident) => {
                const index = incidents.findIndex(x => x.guid === incident.guid);
                incidents.splice(index, 1, incident);
            });

            dispatch(incidentActions.setIncidents(incidents));
        } catch (e) {
            if (e) {
                generalFunctions.showErrorSnackbar('An error occurred while saving incidents', e);
            }
        } finally {
            dispatch(incidentActions.setIncidentLoading(false));
        }

    }
}
