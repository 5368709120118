import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IIncident } from '../../../../@types/model/incident';
import moment from 'moment';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import IncidentFunctions from '../../../../store/incident/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import PrimaryTabs from '../../custom/tab/PrimaryTabs';
import PrimaryTab from '../../custom/tab/PrimaryTab';
import TabViews from '../../custom/tab/TabViews';
import IncidentSummaryView from '../view/SummaryView';
import IncidentComplainantView from '../view/ComplainantView';
import IncidentIncidentView from '../view/IncidentView';
import IncidentSAPSView from '../view/SapsView';
import IncidentSuspectsView from '../view/SuspectsView';
import IncidentEvidenceView from '../view/EvidenceView';
import IncidentFilesView from '../view/FilesView';
import { PDFDownloadLink } from '@react-pdf/renderer';
import IncidentPDF from '../pdf/IncidentPDF';
import IncidentPrintDialog from './IncidentPrintDialog';
import IncidentUpdatesView from '../view/UpdatesView';
import IncidentCourtInfoView from '../view/CourtInfoView';

interface IIncidentDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    incident? : IIncident;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    guid? : string;

    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentDialogState {
    isLoading : boolean;
    tabIndex : number;
}

class IncidentDialog extends React.Component<IIncidentDialogProps, IIncidentDialogState> {
    constructor(props : IIncidentDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            tabIndex: 0,
        };
    }

    public componentDidUpdate = (prevProps : IIncidentDialogProps) => {
        if (prevProps.guid !== this.props.guid) {
            if (this.props.guid) {
                this.loadIncident();
            }
        }

        if (prevProps.incident?.guid !== this.props.incident?.guid) {
            this.setState({
                tabIndex: 0,
            });
        }
    }

    private loadIncident = async () => {
        this.setState({
            isLoading: true,
            tabIndex: 0,
        });

        await IncidentFunctions.getSelected(undefined, this.props.guid);

        if (this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    public onClose = () => {
        this.setState({
            isLoading: false,
        });
        IncidentFunctions.setSelected();

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private onTabChange = (event : React.ChangeEvent<{}>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }

    public render = () => {
        const { tabIndex, isLoading } = this.state;
        const { incident, fullScreen, transition, guid, isEditAdmin, isSaving } = this.props;

        return (
            <Dialog
                    open={!!incident || !!guid}
                    onClose={this.onClose}
                    aria-labelledby='incident-info-dialog-title'
                    aria-describedby='incident-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    fullScreen={fullScreen}
                    scroll='paper'
                >
                    <AppBar position='sticky'>
                        <Toolbar>
                            {
                                incident &&
                                <Typography variant='h6' color='inherit' className='flx1'>
                                    {incident.number} - {moment.utc(incident.date).format('YYYY/MM/DD')} {incident.time}
                                </Typography>
                            }
                            {
                                incident &&
                                <PDFDownloadLink
                                    document={
                                        <IncidentPDF
                                            incident={incident}
                                        />
                                    }
                                    fileName={`${incident.number}.pdf`}
                                    className={'tdn'}
                                    >
                                        {
                                            ({
                                                loading,
                                            }) => (
                                                <Tooltip title='Download'>
                                                    <IconButton color='secondary'>
                                                        {
                                                            loading &&
                                                            <CircularProgress />
                                                        }
                                                        {
                                                            !loading &&
                                                            <GetAppIcon />
                                                        }
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                </PDFDownloadLink>
                            }
                            {
                                incident &&
                                <IncidentPrintDialog fullScreen incident={incident} />
                            }
                            <Tooltip title='Close'>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                        <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                            <PrimaryTab label='SUMMARY' value={0} />
                            <PrimaryTab label='COMPLAINANT' value={1} />
                            <PrimaryTab label='INCIDENT' value={2} />
                            <PrimaryTab label='SAPS INFO' value={3} />
                            <PrimaryTab label='SUSPECTS' value={4} />
                            <PrimaryTab label='EVIDENCE' value={5} />
                            <PrimaryTab label='FILES' value={6} />
                            <PrimaryTab label='COURT INFO' value={7} />
                            <PrimaryTab label='UPDATES' value={8} />
                        </PrimaryTabs>
                    </AppBar>
                    <div className={'fdc hfill oys'}>
                        <div className='fdc flx1'>
                            <div className='fdc flx1'>
                                {
                                    !isLoading && incident &&
                                    <TabViews index={tabIndex}>
                                        <IncidentSummaryView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentComplainantView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentIncidentView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentSAPSView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentSuspectsView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentEvidenceView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentFilesView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentCourtInfoView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                        <IncidentUpdatesView incident={incident} isEditAdmin={isEditAdmin} isSaving={isSaving} />
                                    </TabViews>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isLoading &&
                        <div className='flx1 fdc aic'>
                            <CircularProgress />
                        </div>
                    }
                </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incident: state.incident.selectedIncident,
        isEditAdmin: !!state.auth.session?.user.userRights.some(n => n.rightId === 10),
        isSaving: state.incident.isSaving,
    };
};

export default connect(
    mapStateToProps,
)(IncidentDialog);
